import { useEffect, useState } from "react";
import { Api_Url } from "../api/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLocalStorage } from "../util/storage";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import useAuth from "../../contexts/Auth";

export default function Notecreate() {
    const navigate = useNavigate();
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const { cookies } = useAuth();
    const formik = useFormik({
        initialValues: {
            title: '',
            content: ''
        },
        onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
            try {
                const response = await Api_Url.post('addnotes', values, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${cookies?.token}`
                    }
                });
                if (response.data.status === 'success') {
                    toast.success(response.data.message);

                 await Api_Url.delete('draft', {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${cookies?.token}`
                        }
                    })
                    

                    setTimeout(() => {
                        navigate('/allnotes');
                    }, 3000);
                } else {
                    toast.error(response.data.message);
                }
            } catch (err) {
                toast.error(err.response.data.message);
                setErrors({ api: err.response.data.message });
            } finally {
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        const handleUnload = async () => {
            if (formik.values.content.trim() !== '') {
                try {
                    await Api_Url.post('/draft', { content: formik.values.content }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${cookies?.token}`
                        }
                    });
                } catch (error) {
                    console.error(error);
                }
            }
        };

        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [formik.values.content, cookies]);

    const handleTextareaChange = (event) => {
        formik.handleChange(event);
        setIsSaveDisabled(event.target.value.trim() === '');
    };

    // draft data save in every 10 minut  


    const saveDraft = async () => {
        try {
            const response = await Api_Url.post('draft', 
                { content: formik.values.content }, 
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${cookies?.token}`
                    }
                }
            );
            console.log(response.data.data.content);
            formik.setFieldValue('content', response.data.data.content);
        } catch (error) {
            console.error("Error saving draft:", error);
        }
    };
    
    useEffect(() => {
        const intervalId = setInterval(async () => {
            await saveDraft();
        }, 550000); 
    
        return () => clearInterval(intervalId);
    }, [formik.values.content]);



    useEffect(() => {
        const handleUnload = async () => {
            
                try {
                  const response =   await Api_Url.get('draft', {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${cookies?.token}`
                        }
                    });
                    console.log(response.data.data.content)
                    formik.setFieldValue('content', response.data.data.content);
                } catch (error) {
                    console.error(error);
                }
            
        };
    handleUnload()

    }
    , []);

    return (
        <div>
            <ToastContainer />
            <div className="w-[100%] h-[600px] flex flex-col justify-between bg-[#c8c9cb] p-8">
                <div>
                    <div className="flex justify-between items-center">
                        <div>
                            <h1 className="font-semibold text-black text-2xl ps-5 pt-3">
                                Add Notes
                            </h1>
                        </div>
                        <button
                            className={`px-4 py-2 rounded-lg text-white ${isSaveDisabled ? 'bg-green-600 opacity-60' : 'bg-green-600 opacity-100'}`}
                            onClick={formik.handleSubmit}
                            disabled={isSaveDisabled}
                        >
                            Save
                        </button>
                    </div>
                    <div className="p-4">
                        <textarea
                            className="w-full h-[450px] p-2 border rounded-lg bg-[#ecedef] text-black focus:outline-none focus:ring-2 border-none focus:ring-gray-400"
                            placeholder="Enter Note Content Here....."
                            onChange={handleTextareaChange}
                            name="content"
                            value={formik.values.content}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
