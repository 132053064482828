import './App.css';
import { HashRouter as Router ,Route, Routes, Navigate} from 'react-router-dom';
import Sidebar from './component/layout/Header';
import MainContent from './component/dashoard/dashboard';
import DashboardLayout from './component/dashoard/dashboard';
import Notecreate from './component/page/Notecreate';
import Allnotes from './component/page/Allnotes';
import Login from './component/login/login';
import UpdateNote from './component/page/updateNote';
import { getLocalStorage } from './component/util/storage';
import useAuth from './contexts/Auth';
import { Component } from 'react';
import Message from './component/page/Message';
import DraftPage from './component/page/draftPage';


function App() {
  const {auth}=useAuth();
  return (
     <>
      <Router>
        <Routes>
        <Route path="/login" element={ auth?<Navigate to="/"/>:<Login/>} />

          <Route path="/" element={ (<DashboardLayout/>)} >
          {/* <Route path="" element={<ProtectedRoute auth={auth}><MainContent/></ProtectedRoute>} /> */}
          <Route path="/" element={<ProtectedRoute auth={auth}> <Notecreate/></ProtectedRoute>} />
          <Route path="/allnotes" element={ <ProtectedRoute auth={auth}><Allnotes/></ProtectedRoute>} />
          <Route path="/update/:id" element={<ProtectedRoute auth={auth}> <UpdateNote/></ProtectedRoute>} />
          <Route path="/message" element={<ProtectedRoute auth={auth}> <Message/></ProtectedRoute>} />
          <Route path="/draft" element={<ProtectedRoute auth={auth}> <DraftPage/></ProtectedRoute>} />
          </Route>
        </Routes>
      </Router>
     </>
  );
}

export default App;


const ProtectedRoute=({auth,children})=>{
  
    return auth?children:<Navigate to="/login"/>
  
}